import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import LandingPageImg from "../../images/milestone5_images/landing.png"

import LandingPageClickHeatmap from "../../images/milestone5_images/click_heatmap_landing.jpg"
import LandingPageMoveHeatmap from "../../images/milestone5_images/move_heatmap_landing.jpg"
import LandingPageScrollHeatmap from "../../images/milestone5_images/scroll_heatmap_landing.jpg"

import LoginPageClickHeatmap from "../../images/milestone5_images/click_heatmap_login.jpg"
import LoginPageMoveHeatmap from "../../images/milestone5_images/move_heatmap_login.jpg"
import LoginPageScrollHeatmap from "../../images/milestone5_images/scroll_heatmap_login.jpg"

import DashboardPageClickHeatmap from "../../images/milestone5_images/click_heatmap_dashboard.jpg"
import DashboardPageMoveHeatmap from "../../images/milestone5_images/move_heatmap_dashboard.jpg"
import DashboardPageScrollHeatmap from "../../images/milestone5_images/scroll_heatmap_dashboard.jpg"

import ExplorePageClickHeatmap from "../../images/milestone5_images/click_heatmap_explore.jpg"
import ExplorePageMoveHeatmap from "../../images/milestone5_images/move_heatmap_explore.jpg"
import ExplorePageScrollHeatmap from "../../images/milestone5_images/scroll_heatmap_explore.jpg"

import MonthlyPageClickHeatmap from "../../images/milestone5_images/click_heatmap_monthly.jpg"
import MonthlyPageMoveHeatmap from "../../images/milestone5_images/move_heatmap_monthly.jpg"
import MonthlyPageScrollHeatmap from "../../images/milestone5_images/scroll_heatmap_monthly.jpg"

import "./milestone5.css"

const Milestone5 = () => {
    return <>
        <MilestoneInfoSection title="Preparation Process">
            <p>
                In order to fully prepare for the second stage of the validation process, we had to do several things.
            </p>

            <p>
                The first of these, which we felt would greatly convince people to sign up, was to create a demo version of our application.
                As such, we decided to implement several of the features which we will offer once our product is complete.
            </p>

            <p>
                That being said, many features are still missing, so we had to make it clear that what our potential users are going to see is just
                a preview of what the application might look like. Furthermore, we also decided to add a button that redirects the users to a contact
                form, should they wish to ask us questions or provide feedback.
            </p>

            <p>
                The second thing we had to do was to slightly redesign the landing page. We decided to opt for a simpler call to action, a single form
                where our users could add their email address and they will receive information only when the product launches. We felt that by promising
                a one-time email and no other spam, more users would be encouraged to sign up.
            </p>

            <p>
                Finally, we had to integrate Hotjar into our application in order to measure the activity of our visitors. We decided not to add Google
                Analytics as we felt that Hotjar provided just as much data.
            </p>

            <p>
                With all of this done, we felt that our product was finally ready to be advertised.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={LandingPageImg}
                    imageSize={{width: "640px"}}
                    imageTitle="The updated landing page"
                />
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The Results">
            <p>
                In the same fashion that we validated the problem during the first stage of the validation process, we shared links to our application
                on Facebook, WhatsApp and even through direct conversations with friends and family.
            </p>

            <p> 
                After about a week of gathering data, we have managed to obtain 75 user recordings. Out of these recordings, very few were returning
                users, most of them were from new users. In terms of engagement, our users would spend on average about 2 minutes exploring the features
                of our application.
            </p>

            <p>
                By analyzing the recordings, we were able to see that very few users decided not to try out the demo, which is also a huge reason as to why
                most users would spend two or more minutes with our application.
            </p>

            <p>
                In total, across all our different pages, we have recorded 416 pageviews. The top three most popular pages were the landing page with 173
                pageviews, followed by the login page with 89 pageviews, and finally the dashboard screen with 77 pageviews.

            </p>

            <p>
                By analyzing our Fomrspree entries, out of these 75 recorded users, only 8 decided to sign up to be informed when CookHub finally launches, and
                two others decided to contact us through our contact form. As such, our conversion rate was approximately 10%.
            </p>

            <p>
                Finally, by analysing the referral URL available in Hotjar, we were able to deduce that most of our visitors came from Facebook, and roughly
                70% of them were mobile users. This further enforced the fact that we have to ensure that our application runs as smoothly as possible both on
                personal computers, as well as mobile devices.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Heatmaps">
            <p className="heatmap-section"> Landing Page </p>
            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={LandingPageClickHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Click heatmap for our landing page"
                />
                <ModalImage
                    imageFile={LandingPageMoveHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Mouse movement heatmap for our landing page"
                />
                <ModalImage
                    imageFile={LandingPageScrollHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Scroll heatmap for our landing page"
                />
            </div>

            <p className="heatmap-section"> Login Screen </p>
            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={LoginPageClickHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Click heatmap for our login screen"
                />
                <ModalImage
                    imageFile={LoginPageMoveHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Mouse movement heatmap for our login screen"
                />
                <ModalImage
                    imageFile={LoginPageScrollHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Scroll heatmap for our login screen"
                />
            </div>

            <p className="heatmap-section"> Application Dashboard </p>
            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={DashboardPageClickHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Click heatmap for our dashboard"
                />
                <ModalImage
                    imageFile={DashboardPageMoveHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Mouse movement heatmap for our dashboard"
                />
                <ModalImage
                    imageFile={DashboardPageScrollHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Scroll heatmap for our dashboard"
                />
            </div>

            <p className="heatmap-section"> Explore Recipes </p>
            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={ExplorePageClickHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Click heatmap for our recipe exploration screen"
                />
                <ModalImage
                    imageFile={ExplorePageMoveHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Mouse movement heatmap for our recipe exploration screen"
                />
                <ModalImage
                    imageFile={ExplorePageScrollHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Scroll heatmap for our recipe exploration screen"
                />
            </div>

            <p className="heatmap-section"> Monthly Challenges </p>
            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={MonthlyPageClickHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Click heatmap for our monthly challenges screen"
                />
                <ModalImage
                    imageFile={MonthlyPageMoveHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Move heatmap for our monthly challenges screen"
                />
                <ModalImage
                    imageFile={MonthlyPageScrollHeatmap}
                    imageSize={{width: "340px"}}
                    imageTitle="Scroll heatmap for our monthly challenges screen"
                />
            </div>
        </MilestoneInfoSection>
    </>
}

export default Milestone5