import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"

import "./milestone1.css"

import TastyLogo from "../../images/milestone1_images/tasty.png"

import KeyMetric1 from "../../images/milestone1_images/key_metric_1.png"
import KeyMetric2 from "../../images/milestone1_images/key_metric_2.png"
import KeyMetric3 from "../../images/milestone1_images/key_metric_3.png"
import KeyMetric4 from "../../images/milestone1_images/key_metric_4.png"
import KeyMetric5 from "../../images/milestone1_images/key_metric_5.png"
import KeyMetric6 from "../../images/milestone1_images/key_metric_6.png"
import KeyMetric7 from "../../images/milestone1_images/key_metric_7.png"

const KeyMetricItem = ({ image, name }) => (
    <div className="key-metric-container">
        <img className="key-metric-image" src={image} alt="metric_image"/>
        <p className="key-metric-name"> {name} </p>
    </div>
)

const ProgressBar = ({ name, procent }) => (
    <div className="progress-bar-container">
        <div className="progress-bar-information">
            <p className="progress-bar-title"> {name} </p>
            <p className="progress-bar-procent"> {procent} </p>
        </div>
        <div className="progress-bar-background">
            <div
                className="progress-bar-color"
                style={{width: procent}}
            />
        </div>
    </div>
)

const Milestone1 = () => {
    return <>
        <MilestoneInfoSection title="The Problem">
            <p>
                Modern people face difficulties in learning how to cook, mostly due to the fact that cooking is a very time-consuming task, but also
                because it is quite hard to choose a recipe from the abundance of online sources.
                Furthermore, you may often find recipes that are not suitable to your level of expertise, involving either uncommon ingredients which
                bring little taste to the dish or requiring difficult techniques that may ruin your cooking experience.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Our Solution">
            <p>
                CookHub is a cooking companion that will help you organize your meals, offering you recipe recommendations based on your preferences and
                nutritional needs. With the use of machine learning algorithms, as well as several gamification mechanics, we will ensure that our
                recommendations will not only fit you, but they will also make you come back to our platform.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Customer Segments">
            <p>
                The majority of our customers will consist of young people that have little to no experience in the kitchen and want a little extra motivation
                and help in their cooking endeavours. Additionally, our application will also help those that want to have an organized experience when cooking
                and eating, as CookHub will be the perfect companion of any amateur cook.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Competition">
            <div className="competition-wrapper">
                <p>
                    Currently, the most relevant competitor on the market is the Tasty app, which has over 10.000.000 downloads and presents short video-based
                    recipes that are easy to follow and prepare. Tasty offers over 3000 recipes from multiple categories with step-by-step instructions.
                </p>

                <a className="tasty-link" href="https://tasty.co/" target="_blank" rel="noreferrer">
                    <img 
                        className="competition-logo"
                        src={TastyLogo}
                        alt="tasty_logo"
                    />
                </a>
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Advantages Over the Competition">
            <p>
                Our main advantage over the competition is provided by our gamification features that will create a more rewarding and enjoyable experience
                for our users. Our aim is to make cooking as engaging as possible so that beginners can progress and learn how to cook at their own pace, while
                also having a fun and pleasant experience in doing so.
            </p>

            <p>
                Moreover, as mentioned above, our recommendation system will take into account the personal needs and desires of each user. Thus, CookHub will
                not be a simple recipe website, but rather a digital companion that will ease the burden of cooking.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Key Metrics">
            <div className="key-metrics-wrapper">
                <KeyMetricItem
                    image={KeyMetric1}
                    name="User Engagement"
                />

                <KeyMetricItem
                    image={KeyMetric2}
                    name="User Retention"
                />

                <KeyMetricItem
                    image={KeyMetric3}
                    name="Feature Relevance"
                />

                <KeyMetricItem
                    image={KeyMetric4}
                    name="Customer Lifetime Revenue"
                />

                <KeyMetricItem
                    image={KeyMetric5}
                    name="Backend Metrics"
                />

                <KeyMetricItem
                    image={KeyMetric6}
                    name="Return on advertising spending"
                />

                <KeyMetricItem
                    image={KeyMetric7}
                    name="Return on social media activity"
                />
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Cost Structure">
            <div className="cost-structure-wrapper">
                <div className="cost-structure-column">
                    <p className="cost-structure-column-title"> Fixed Costs</p>

                    <ProgressBar
                        name="Backend & Frontend Development"
                        procent="30%"
                    />
                    <ProgressBar
                        name="Maintenance"
                        procent="10%"
                    />
                    <ProgressBar
                        name="Site Domains"
                        procent="5%"
                    />
                    <ProgressBar
                        name="Marketing & Advertisements"
                        procent="40%"
                    />
                </div>

                <div className="cost-structure-column">
                    <p className="cost-structure-column-title"> Variable Costs</p>

                    <ProgressBar
                        name="Hosting Services"
                        procent="10%"
                    />
                    <ProgressBar
                        name="Metrics"
                        procent="5%"
                    />
                </div>
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Revenue Streams">
            <p>
                One essential revenue stream would be partnerships with restaurants which offer delivery services. Since no one likes to spend a lot of time cooking a
                recipe only for them to later find out they do not like it, with each recipe we will also provide a link to the closest restaurant that sells food similar
                to the one presented in the recipe. Additionally, we can also generate revenue by using non-intrusive advertisements to promote partners or other food
                related products.
            </p>
        </MilestoneInfoSection>
    </>
}

export default Milestone1