import React from "react"
import PropTypes from "prop-types"

import "./index.css"

const MilestoneInfoSection = ({ title, children }) => (
    <div className="milestone-info-section">
        <p className="milestone-info-section-title"> {title} </p>
        {children}
    </div>
)

MilestoneInfoSection.propTypes = {
    title: PropTypes.string,
}

MilestoneInfoSection.defaultProps = {
    title: "",
}

export default MilestoneInfoSection
