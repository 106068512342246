import React from "react"
import PropTypes from "prop-types"
import { MdClose } from 'react-icons/md';

import "./index.css"

class MilestonesItem extends React.Component {
    _isMounted = false;

    state = {
        active: false,
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.closeDescription();
        }
    }

    openDescription() {
        if(this._isMounted && !this.props.disabled) {
            document.addEventListener("keydown", (e) => this.escFunction(e), false);
            document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
            this.setState({active: true})
        }
    }

    closeDescription() {
        if(this._isMounted && !this.props.disabled) {
            document.removeEventListener("keydown", (e) => this.escFunction(e), false);
            document.getElementsByTagName('html')[0].style.overflowY = 'auto';
            this.setState({active: false})
        }
    }

    componentDidMount() {
		this._isMounted = true;
  	}

  	componentWillUnmount() {
		this._isMounted = false;
  	}

    render() {
        return(
            <>
                <li className="timeline-item">
                    <div className="timeline-item-icon"/>
                    <div
                        className={"timeline-item-info" + (this.props.disabled ? "" : " clickable")}
                        role="button"
                        onClick={() => this.openDescription()}
                        onKeyPress={() => 0}
                        tabIndex={0}
                    >
                        <p className="timeline-item-date"> {this.props.date} </p>

                        <div className="milestone-item-text">
                            <p className="milestone-item-title"> {this.props.title} </p>

                            {this.props.disabled
                                ? this.props.children
                                : <p className="milestone-item-description"> {this.props.description} </p>
                            }
                        </div>
                    </div>
                </li>

                {!this.props.disabled && this.state.active &&
                    <div className="full-description-wrapper">
                        <MdClose
                            className="close-button" 
                            onClick={() => this.closeDescription()}
                        />

                        <div className="milestone-description-content">
                            <p className="milestone-description-title"> {this.props.title} </p>
                            {this.props.children}
                        </div>
                    </div>
                }
            </>
        );
    }
}

MilestonesItem.propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
}

MilestonesItem.defaultProps = {
    date: "NO DATA PROVIDED",
    title: "NO DATA PROVIDED",
    description: "NO DATA PROVIDED",
}

export default MilestonesItem
