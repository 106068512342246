import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import AudioPlayer from 'react-h5-audio-player';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import ModalImage from "../ModalImage"

import 'react-h5-audio-player/lib/styles.css';
import "./milestone4.css";

import interview1 from '../../audio/interview_1.m4a'
import interview2 from '../../audio/interview_2.m4a'
import interview3 from '../../audio/interview_3.m4a'

import userImage1 from '../../images/milestone4_images/user1.svg';
import userImage2 from '../../images/milestone4_images/user2.svg';
import userImage3 from '../../images/milestone4_images/user3.svg';

import userFlow from '../../images/milestone4_images/user_flow.png'

const FikiAudioPlayer = ({ title, audioFile }) => (
    <AudioPlayer
        header={title}
        src={audioFile}
        showJumpControls={false}
        showDownloadProgress={false}
        customAdditionalControls={[]}
        customIcons={{
            play: <MdPlayArrow/>,
            pause: <MdPause/>,
        }}
    />
)

const ColumnList = ({title, content}) => (
    <div className="user-persona-column">
        <p className="user-persona-column-title"> {title} </p>
        {content.map((entry, index) => 
            <p key={index} className="user-persona-column-entry"> {entry} </p>)
        }
    </div>
)

const UserPersona = ({ pic, info, desc, problems, needs, story }) => (
    <div className="user-persona-container">
        <div className="user-persona-info">
            <img className="user-persona-img" src={pic} alt="user_persona"/>
            <div className="user-persona-text">
                <p>
                    <strong>Name: </strong>
                    {info.name}
                </p>
                <p>
                    <strong>Age: </strong>
                    {info.age}
                </p>
                <p>
                    <strong>Occupation: </strong>
                    {info.occupation}
                </p>
                <p>
                    <strong>Location: </strong>
                    {info.location}
                </p>
            </div>
        </div>

        <div className="user-persona-description">
            <p><strong> Bio </strong></p>
            <p> {desc} </p>
        </div>

        <div className="user-persona-columns">
            <ColumnList title='Problems' content={problems}/>
            <ColumnList title='Needs' content={needs}/>
        </div>

        <div className="user-persona-story">
            <p><strong> User Story </strong></p>
            <p> As a client, I want to be able to: </p>
            <ul className="user-persona-story-list">
                {story.map((entry, index) => 
                    <li key={index} className="user-persona-story-list-entry"> {entry} </li>)
                }
            </ul>
        </div>
    </div>
)

const Milestone4 = () => {
    return <>
        <MilestoneInfoSection title="User Interviews">
            <p> 
                For our phone interviews, we prepared the following questions in order to understand our main demographic: young adults with
                basic or no cooking skills, and with little to no motivation.
                The most important questions we chose are as follows:
            </p>

            <ul className="user-interviews-questions">
                <li> On average, how long do you cook for? (Days per week and hours per day) </li>
                <li> When was the last time when you have tried to cook something new and how often do you do that? </li>
                <li> How do you choose what to cook? </li>
                <li> How good do you think your cooking skills are? </li>
                <li> Do you want to improve your skills? What stops you from doing that? </li>
                <li> Do you consume cooking content on social media? If yes, in what form? (Instagram, YouTube, TikTok) </li>
            </ul>

            <p>
                The interview process was as follows: we started by asking our interviewees some of the questions outlined above and
                recording their answers. After that, we stopped the recordings and we continued to ask the interviewees other questions
                and generally asking them for suggestions and feedback. The second part usually lasted somewhere between 15 to 30 minutes.
            </p>

            <p className="user-interviews-recordings-text"> Below, you can find the recordings of the first sections of the interviews: </p>

            <div className="user-interviews-recordings">
                <FikiAudioPlayer title='User Interview 1' audioFile={interview1}/>
                <FikiAudioPlayer title='User Interview 2' audioFile={interview2}/>
                <FikiAudioPlayer title='User Interview 3' audioFile={interview3}/>
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="User Profiles">
            <UserPersona
                pic={userImage1}
                info={{name: 'Daniel', age: 23, occupation: 'Student', location: 'Bucharest, Romania'}}
                desc="Daniel is 23 years old and lives in Bucharest with his family. He is a student at the Politehnica University
                    of Bucharest. During his free time, Daniel likes to go out with his friends, to play video games, and to go jogging.
                    Unfortunately, Daniel is not that great at cooking. Despite wanting to get better at it, he never has the motivation to
                    do so."
                problems={
                    ["He is not that good at cooking, mostly ordering food or eating what his mother made",
                     "He lacks the motivation to get better at cooking"]
                }
                needs={
                    ["A way of progressively improving his cooking skill, teaching him the ropes step by step",
                     "A way of maintaining the motivation needed to improve"]
                }
                story={
                    ["Learn simple recipes so that will help me improve my cooking skills gradually",
                     "Share my dishes with my friends who are interested in cooking so that we have something to talk about",
                     "Have weekly challenges so that I am pushed to become better at cooking",
                     "Have rewards for each challenge completed so that I will feel like I am improving",
                     "Adjust the recipes filter to my needs so that I don’t need special equipment or fancy ingredients"]
                }
            />

            <UserPersona
                pic={userImage2}
                info={{name: 'Alexandra', age: 35, occupation: 'Manager', location: 'Brașov, Romania'}}
                desc="Alexandra is 35 years old and lives in Brașov with her husband. She is a manager at a local software company, and she
                    spends most of her time at work. When she is home however, she likes to watch movies and cook. Despite being quite knowledgeable
                    in the art of cooking, she would like to expand her knowledge by learning new recipes that are based on her preferences."
                problems={
                    ["She would like to learn new and exciting recipes",
                     "She is a vegetarian and would like to receive suggestions based on her preferences"]
                }
                needs={
                    ["A way to learn new recipes easily and constantly",
                     "A way of receiving suggestions that are based on her lifestyle"]
                }
                story={
                    ["Filter recipes based on my lifestyle so that I can find a recipe that I like faster",
                     "Receive statistics based on the recipes I cook so that I can diversify my dishes and not get stuck in a routine",
                     "Access new recipes and cooking techniques so that I will  be able to further improve",
                     "Adjust the recipes so that I will waste as little ingredients as possible"]
                }
            />

            <UserPersona
                pic={userImage3}
                info={{name: 'Gordon', age: 54, occupation: 'Chef', location: 'Cluj, Romania'}}
                desc="Gordon is 54 years old and lives in Cluj with his wife and five kids. He is the head chef of a very successful local restaurant.
                    He considers himself a master chef and likes to prepare the most exquisite dishes. Furthermore, he also loves to share his recipes
                    with others. However, he would like a place where he could showcase all his culinary achievements, while also interacting with his
                    friends."
                problems={
                    ["He wants to share with others the recipes that he knows, while also promoting his restaurant",
                     "He wants to showcase the culinary achievements that he has obtained"]
                }
                needs={
                    ["A way of displaying his achievements",
                     "A way of sharing recipes and interacting with his audience"]
                }
                story={
                    ["Display and share my performances so that people will observe my achievements",
                     "Gain influence on a dedicated cooking social platform so that I can promote my restaurant",
                     "Create a community around my account so that I will be able to help more people to get better at cooking and to enjoy fancier dishes"]
                }
            />
        </MilestoneInfoSection>

        <MilestoneInfoSection title="User Flow">
            <ModalImage
                imageFile={userFlow}
                imageSize={{width: "90%"}}
                imageTitle="User Flow"
                isDarkImage={true}
            />
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The Wireframes">
            <p>
                Based on the interviews, we feel that the initial design of the application doesn't need to be updated as it is intuitive
                and allows the users to easily access the information that they would be looking for.
            </p>

            <p>
                That being said, the design of the application may change once we start development. Features may be added, changed or, in the worst
                case possible, removed. This however, as mentioned before, will be determined during the development process.
            </p>
        </MilestoneInfoSection>
    </>
}

export default Milestone4