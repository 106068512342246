import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import Bar1 from "../../images/milestone8_images/bar1.png"
import Bar2 from "../../images/milestone8_images/bar2.png"

import Pie1 from "../../images/milestone8_images/pie1.png"
import Pie2 from "../../images/milestone8_images/pie2.png"
import Pie3 from "../../images/milestone8_images/pie3.png"
import Pie4 from "../../images/milestone8_images/pie4.png"

const Milestone7 = () => {
    return <>
        <MilestoneInfoSection title="Preparing for the users">
            <p>
                With the MVP online and functional, it was now time to see what our potential users thought of the application. As such, as we have done before,
                we decided to use various social media platforms such as Facebook and WhatsApp to tell people about the app.
            </p>

            <p>
                In other to gather their feedback, we also created a Google Form which our users could complete after they have interacted with the MVP. The form
                can be accessed&#160;
                <a className="landing-page-link" href="https://forms.gle/JiyLLZo1WksBAfCs7" target="_blank" rel="noreferrer"> 
                    here.
                </a>
            </p>

            <p>
                Furthermore, the MVP can be accessed&#160;
                <a className="landing-page-link" href="https://cookhub.coiotii.com/login" target="_blank" rel="noreferrer"> 
                    here.
                </a>
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The feedback">
            <p>
                At the time of writing this article, we have collected over 30 responses.
            </p>

            <p>
                Almost all the users considered the interface of the application very intuitive and friendly to use. From the 32 responses, the average
                rating was 9.34 out of 10. We feel that this is about what we expected as there are a couple of areas that could do with some improvements, but
                the overall interface is solid and very polished.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Bar1}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
            </div>

            <p>
                When asked what features they found most useful, the responses from our users were very mixed. The three most popular features, according to our
                users, are as follows: the periodic challenges, the activity feed and finally the achievements. By looking at the data, we were able to determine
                that the more experienced users preferred the more social aspects of the platform, such as the activity feed or the achievements, while the more
                unexperienced users preferred the challenges and the more interactive aspects of the application.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Pie2}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
                <ModalImage
                    imageFile={Pie3}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
            </div>

            <p>
                We also asked our users how often they would use this application. This was to see if their responses would line up with those we received from the
                2nd milestone. Thankfully, the majority answered that they would use the application weekly or even daily, responses which were similar to the ones
                from the first validation stage.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Pie4}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
            </div>

            <p>
                What surprised us most is how much our users would be willing to pay for the application. Despite the fact that we intend to make the application
                free of charge and generate income based on advertisements and sponsorship deals, the average price that our users would be willing to spend on
                CookHub is 7€.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Pie1}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
            </div>

            <p>
                Finally, when asked how likely it would be for them to recommend our application, over 50% of the users said they would definitely recommend this
                app to their friends and family.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Bar2}
                    imageSize={{width: "440px"}}
                    isDarkImage={true}
                />
            </div>

            <p>
                Finally, we must also mention that there have been numerous great ideas given by those who were kind enough to leave a feedback, some that we have
                thought of (such as dark mode and social media integration) and some that we did not (such as community-wide challenges, customizable UI elements,
                premium versions, and many others). Overall, with the numerous additional features that our potential users have suggested, we feel that CookHub
                could evolve into something much more complex than we have imagined.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Conclusions">
            <p>
                To say that we were overwhelmed by the amount of positive feedback would be an understatement. Although we wanted to do more, it is clear to us
                that we have managed to deliver something that could potentially become a viable product, and this was obviously reflected in the feedback.
            </p>
        </MilestoneInfoSection>
    </>
}

export default Milestone7