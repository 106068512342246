import React from "react"
import MilestoneItem from "../MilestoneItem"

import Milestone1 from "../Milestones/milestone1"
import Milestone2 from "../Milestones/milestone2"
import Milestone3 from "../Milestones/milestone3"
import Milestone4 from "../Milestones/milestone4"
import Milestone5 from "../Milestones/milestone5"
import Milestone6 from "../Milestones/milestone6"
import Milestone7 from "../Milestones/milsetone7"
import Milestone8 from "../Milestones/milsetone8"

import "./index.css"

const MilestonesSection = () => (
    <div id="milestones" className="milestones-section">
        <div className="milestones-wrapper">
            <div className="milestones-header-container">
                <p className="section-title"> Project Timeline </p>
                <div className="divider div-transparent div-arrow-down"/>
            </div>

            <ul className="timeline">
                <MilestoneItem
                    date="19 October 2020"
                    title="Milestone 1 - Project Description"
                    description="A short description of CookHub and our goals"
                >
                    <Milestone1/>
                </MilestoneItem>

                <MilestoneItem
                    date="2 November 2020"
                    title="Milestone 2 - Validation 1"
                    description="The first stages of the product validation process"
                >
                    <Milestone2/>
                </MilestoneItem>

                <MilestoneItem
                    date="9 November 2020"
                    title="Milestone 3 - Wireframe and Landing Page"
                    description="A quick summary of the design process of the application layout"
                >
                    <Milestone3/>
                </MilestoneItem>

                <MilestoneItem
                    date="16 November 2020"
                    title="Milestone 4 - User Experience"
                    description="A detailed look at our possible users and the use flow of our application"
                >
                    <Milestone4/>
                </MilestoneItem>

                <MilestoneItem
                    date="7 December 2020"
                    title="Milestone 5 - Validation 2"
                    description="The second stage of the product validation process"
                >
                    <Milestone5/>
                </MilestoneItem>

                <MilestoneItem
                    date="7 December 2020"
                    title="Milestone 6 - Market Research"
                    description="An overview of the market research process we carried out"
                >
                    <Milestone6/>
                </MilestoneItem>

                <MilestoneItem
                    date="11 January 2021"
                    title="Milestone 7 - Minimum Viable Product"
                    description="A detailed description of how we created the first version of CookHub"
                >
                    <Milestone7/>
                </MilestoneItem>

                <MilestoneItem
                    date="11 January 2021"
                    title="Milestone 8 - First Sale"
                    description="An extensive summary of the first sale experience of CookHub"
                >
                    <Milestone8/>
                </MilestoneItem>

                <MilestoneItem
                    date="11 January 2021"
                    title="Milestone 9 - Final Presentation"
                    disabled={true}
                >
                    <p className="milestone-item-description">
                        You can find our final presentation slides&#160;
                        <a className="landing-page-link" href="./src/files/4.CookHub.pdf" download> 
                            here.
                        </a>
                    </p>
                </MilestoneItem>
            </ul>
        </div>
    </div>
)

export default MilestonesSection
