import React from "react"
import PropTypes from "prop-types"

import "./index.css"

const TeamMember = ({image, name, email, role}) => (
    <div className="team-member-container">
        <div className="team-member-image">
            <img src={image} alt="member_picture"/>
        </div>
        <div className="team-member-description">
            <p className="team-member-name"> {name} </p>
            <a href={"mailto:" + email} className="team-member-email"> {email} </a>
            <p className="team-member-role"> {role} </p>
        </div>
    </div>
)

TeamMember.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
}

TeamMember.defaultProps = {
    image: null,
    name: "NO DATA PROVIDED",
    email: "NO DATA PROVIDED",
    role: "NO DATA PROVIDED",
}

export default TeamMember
