import React from "react"

import Logo from "../../images/logo.svg"

import "./index.css"

const LandingSection = () => (
    <div id="home" className="landing-section">
        <div className="landing-section-text">
            <img className="cookhub-logo-image unselectable" src={Logo} alt="cookhub_logo"/>
            <h1 className="cookhub-logo unselectable"> CookHub </h1>
            <h2 className="cookhub-logo-motto unselectable"> Challenge your appetite </h2>
        </div>
    </div>
)

export default LandingSection
