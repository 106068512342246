import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import SavoriUrbane from '../../images/milestone6_images/savoriurbane.png'
import Bucataras from '../../images/milestone6_images/bucataras.png'
import Jamila from '../../images/milestone6_images/jamila.png'
import Poftabuna from '../../images/milestone6_images/poftabuna.png'
import Lauralaurentiu from '../../images/milestone6_images/lauralaurentiu.png'
import MarketShare from '../../images/milestone6_images/market_share.png'
import MarketPerc from '../../images/milestone6_images/marketperc.png'
import MarketVal from '../../images/milestone6_images/marketval.png'

import "./milestone6.css"

const GraphContainer = ({ image }) => (
    <ModalImage
        imageFile={image}
        imageSize={{width: "520px"}}
        isDarkImage={true}
    />
)

const Milestone6 = () => {
    return <>
        <MilestoneInfoSection title="Local market size">
            <p>
                As we looked on external data on the market size of the cooking apps
                in Romania, we found on similarweb.com that in our country there are
                5 relevant competitors on the cooking and recipes category:
                savoriurbane.com, bucataras.ro, jamilacuisine.ro, pofta-buna.com and
                lauralaurentiu.ro.
            </p>
            
            <p>
                We calculated the market size in views per month
                as our business model that runs on ads is strongly correlated with
                this number. In total, the number of average views per month on these
                websites is over 13 millions views. We do not need the unique user
                count as we do not intend at this stage to sell any service or
                product to a distinct user, but we estimated by the number of
                subscribers and followers of these websites / influencers that the
                number of unique users and views per month to be roughly the same.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Our place in the market">
            <div className="landing-image-wrapper">
                <GraphContainer image={MarketShare}/>
            </div>

            <p>
                The main difference between us and our competitors, based on the data
                from similarweb.com, is that they only offer recipes in Romanian and
                not much else, as over 90% of the traffic on these websites comes
                from search and social media, mainly Facebook and YouTube. 
            </p>

            <p>
                We concluded based on the most used search keywords that people only
                interact with these websites when they specifically search for a
                recipe, or click on a recipe shared by their friends. However, we
                intend to build our app as a place where people can come back to on a
                daily basis, our users being motivated to do so by the periodic challenges,
                as well as by the constant interaction with their friends and with the
                community.
            </p>

            <p>
                This is the main reason why we believe we have a place in this market and we
                can sustain constant growth.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The main competitors">
            <p>
                Seeing how CookHub is going to initially launch in Romania only, we decided to focus only
                on local competitors. After doing some research, we identified 5 major competing websites / platforms
                that offer alternatives to the functionalities that we propose.
            </p>

            <p>
                In increasing order of market importance, these are:
            </p>

            <div className="competitors-list">
                <div className="competitor-container">
                    <p>
                        <b> 1. Savori Urbane - 5.23M </b> monthly views 
                    </p>
                    <a className="savori" href="https://savoriurbane.com" target="_blank" rel="noreferrer">
                        <img 
                            className="savori-urbane-logo"
                            src={SavoriUrbane}
                            alt="SavoriUrbane"
                        />
                    </a>
                </div>

                <div className="competitor-container">
                    <p>
                        <b> 2. Bucataras - 1.66M</b> monthly views
                    </p>
                    <a className="bucataras" href="https://www.bucataras.ro/" target="_blank" rel="noreferrer">
                        <img 
                            className="Bucataras-logo"
                            src={Bucataras}
                            alt="Bucataras"
                        />
                    </a>
                </div>
                
                <div className="competitor-container">
                    <p>
                        <b> 3. Jamila Cuisine - 1.38M </b> monthly views
                    </p>
                    <a className="jamila" href="https://jamilacuisine.ro/" target="_blank" rel="noreferrer">
                        <img 
                            className="Jamila-logo"
                            src={Jamila}
                            alt="Jamila"
                        />
                    </a>
                </div>
                
                <div className="competitor-container">
                    <p>
                        <b> 4. Poftă Bună - 2.53M </b> monthly views
                    </p>
                    <a className="poftabuna" href="https://pofta-buna.com/" target="_blank" rel="noreferrer">
                        <img 
                            className="poftabuna-logo"
                            src={Poftabuna}
                            alt="Poftabuna"
                        />
                    </a>
                </div>
                
                <div className="competitor-container">
                    <p>
                        <b> 5. Laura Laurențiu - 2.37M </b> monthly views
                    </p>
                    <a className="lauralaurentiu" href="https://www.lauralaurentiu.ro/" target="_blank" rel="noreferrer">
                        <img 
                            className="lauralaurentiu-logo"
                            src={Lauralaurentiu}
                            alt="Lauralaurentiu"
                        />
                    </a>
                </div>
            </div>

            <p>
                With the exception of Jamila Cuisine (which aims to offer a website only with video
                recipes) and Bucataras (which enables you to create your own account and save
                recipes), the other websites estabilish their presence in the market solely by
                diversity of recipes and cross-promotion through other channels of media (such
                as Facebook pages and YouTube channels).
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Potential market share in the first 5 years">
            <div className="landing-image-wrapper">
                <GraphContainer image={MarketPerc}/>
            </div>

            <div className='tables'>
                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 1</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market share</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>39.5%</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>14%</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>13%</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>18%</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>15%</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>0.5%</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 2</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market share</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>39.2%</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>14%</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>14%</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>17%</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>14.8%</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>1%</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 3</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market share</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>39.2%</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>13%</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>15%</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>15%</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>14.8%</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>3%</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 4</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market share</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>36.2%</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>13%</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>18%</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>14%</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>12.8%</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>6%</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 5</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market share</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>34.2%</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>12%</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>18%</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>14%</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>11.8%</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>10%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Potential market value in the first 5 years">
            <p>
                Our formula for estimating the market value is: 
            </p>
            
            <p className="market-value-formula">
                Market Value = Market Size × Click Rate × Price Per Ad
            </p>               

            <p>
                A typical approximation of a good <b>click rate</b> is in the range of 1.5-2%.
            </p>

            <p>
                Regarding the price of an ad, we could opt either for <i>banner ads</i> which
                come with a revenue of 0.1$ per ad or <i>interstitials ads</i> which come
                with a revenue of between 1-3$.
            </p>

            <p>
                Even though the latter offer a significant increase in profits, we must keep
                in mind that the presence of ads on the page has a non-negligible impact on
                the user's experience and consequently on the user's conversion rate.
            </p>

            <p>
                For this reason, our best strategy would be to slowly roll out over time
                the more attention grabbing adverts, on a steady basis, in correlation with
                the increase of our user base and of our market share.
            </p>

            <p>
                One significant detail we would like to point out is the fact that, supposing
                a market share of 0.5% in the first year, we would be able to fully support our
                server expenses using revenue generated solely from banner ads.
            </p>

            <div className="landing-image-wrapper">
                <GraphContainer image={MarketVal}/>
            </div>

            <div className='tables'>
                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 1</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market size (in million of users)</th>
                            <th>Market value (in dollars)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>5.14</td>
                            <td>77025</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>1.82</td>
                            <td>27300</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>1.69</td>
                            <td>25350</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>2.34</td>
                            <td>35100</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>1.95</td>
                            <td>29250</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>0.06</td>
                            <td>975</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 2</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market size (in million of users)</th>
                            <th>Market value (in dollars)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>5.17</td>
                            <td>77616</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>1.85</td>
                            <td>27720</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>1.85</td>
                            <td>27720</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>2.24</td>
                            <td>33660</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>1.95</td>
                            <td>29250</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>0.13</td>
                            <td>1980</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 3</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market size (in million of users)</th>
                            <th>Market value (in dollars)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>5.34</td>
                            <td>80129</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>1.77</td>
                            <td>26573</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>2.04</td>
                            <td>30661</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>2.32</td>
                            <td>34749</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>2.02</td>
                            <td>30252</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>0.41</td>
                            <td>6132</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 4</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market size (in million of users)</th>
                            <th>Market value (in dollars)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>5.21</td>
                            <td>78192</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>1.87</td>
                            <td>28080</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>2.59</td>
                            <td>38880</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>2.02</td>
                            <td>30240</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>1.84</td>
                            <td>27648</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>0.86</td>
                            <td>12960</td>
                        </tr>
                    </tbody>
                </table>

                <table className="zui-table">
                    <thead>
                        <tr>
                            <td>YEAR 5</td> 
                        </tr>
                        <tr>
                            <th>Competitor</th>
                            <th>Market size (in million of users)</th>
                            <th>Market value (in dollars)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>savoriurbane.com</td>
                            <td>5.13</td>
                            <td>76950</td>
                        </tr>
                        <tr>
                            <td>bucataras.ro</td>
                            <td>1.8</td>
                            <td>27000</td>
                        </tr>
                        <tr>
                            <td>jamilacusisine.ro</td>
                            <td>2.7</td>
                            <td>40499</td>
                        </tr>
                        <tr>
                            <td>pofta-buna.com</td>
                            <td>2.1</td>
                            <td>31500</td>
                        </tr>
                        <tr>
                            <td>lauralaurentiu.ro</td>
                            <td>1.77</td>
                            <td>26550</td>
                        </tr>
                        <tr>
                            <td><b>cookhub.com</b></td>
                            <td>1.5</td>
                            <td>22500</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </MilestoneInfoSection>

    </>
}

export default Milestone6