import React from "react"
import TeamMember from "../TeamMember"

import "./index.css"

import lucaImg from '../../images/members/luca.jpg'
import danutImg from '../../images/members/danut.jpg'
import vasileImg from '../../images/members/vasile.jpg'
import medarImg from '../../images/members/medar.jpg'
import teoImg from '../../images/members/teo.jpg'

const TeamSection = () => (
    <div id="team" className="team-section">
        <p className="section-title-alt"> The Team </p>
        <div className="divider div-transparent-green"/>

        <div className="team-members">
            <TeamMember
                image={danutImg}
                name="Gabriel-Dănuț Matei"
                email="matei.danut.dm@gmail.com"
                role="Project Manager"
            />
            <TeamMember
                image={medarImg}
                name="Andrei Medar"
                email="andreimedar@gmail.com"
                role="Backend Developer"
            />
            <TeamMember
                image={teoImg}
                name="Teodor-Daniel Milea"
                email="teo.milea97@gmail.com"
                role="Data Scientist"
            />
            <TeamMember
                image={lucaImg}
                name="Luca Istrate"
                email="lucaistrate@gmail.com"
                role="Database Administrator"
            />
            <TeamMember
                image={vasileImg}
                name="Cristian-Ștefan Vasile"
                email="cristianvasile23@gmail.com"
                role="Frontend Developer"
            />
        </div>
    </div>
)

export default TeamSection
