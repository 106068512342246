import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import LandingSection from "../components/SectionLanding"
import MilestonesSection from "../components/SectionMilestones"
import TeamSection from "../components/SectionTeam"

import "../css/global.css"

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>

        <LandingSection/>
        <MilestonesSection/>
        <TeamSection/>
        
        {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
            <Image />
        </div> */}
    </Layout>
)

export default IndexPage
