import React from 'react';
import Lightbox from "react-image-lightbox";

import 'react-image-lightbox/style.css';
import './index.css'

class ModalImage extends React.Component {
	_isMounted = false;

	state = {
		isOpen: false
	}

	componentDidMount() {
		this._isMounted = true;
  	}

  	componentWillUnmount() {
		this._isMounted = false;
	}
	  
	openModal() {
		if (this._isMounted) {
			this.setState({ isOpen: true })
		}
	}

	closeModal() {
		if (this._isMounted) {
			this.setState({ isOpen: false })
		}
	}

  	render() {
		const imageFile = this.props.imageFile;
		const imageSize = this.props.imageSize;
		const imageTitle = this.props.imageTitle;
		const isDarkImage = this.props.isDarkImage;

		let imageStyle = { ...imageSize };
		if (this.props.hasPadding) {
			imageStyle.padding = "10px"
		}
		
		return (
			<div className="modal-image-container">
				<div
					role="button"
					className="modal-image"
					onClick={() => this.openModal()}
					onKeyPress={() => 0}
					tabIndex={0}
				>
					<img src={imageFile} style={imageStyle} alt="Modal"/> 
				</div>
		
				{this.state.isOpen && 
					<Lightbox
						mainSrc={imageFile}
						wrapperClassName={"modal-image-wrapper" + (isDarkImage ? " light-mode" : " dark-mode")}
						imageCaption={imageTitle}
						animationDuration={0}
						animationDisabled={true}
						imagePadding={50}
						onCloseRequest={() => this.closeModal()}
					/>
				}
			</div>
		);
	}
}

export default ModalImage;