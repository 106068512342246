import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import PieGraph1 from "../../images/milestone2_images/pie1.png"
import PieGraph2 from "../../images/milestone2_images/pie2.png"
import PieGraph3 from "../../images/milestone2_images/pie3.png"
import PieGraph4 from "../../images/milestone2_images/pie4.png"
import PieGraph5 from "../../images/milestone2_images/pie5.png"
import PieGraph6 from "../../images/milestone2_images/pie6.png"
import PieGraph7 from "../../images/milestone2_images/pie7.png"
import PieGraph8 from "../../images/milestone2_images/pie8.png"
import PieGraph9 from "../../images/milestone2_images/pie9.png"

import BarsGraph1 from "../../images/milestone2_images/bars1.png"
import BarsGraph2 from "../../images/milestone2_images/bars2.png"
import BarsGraph3 from "../../images/milestone2_images/bars3.png"
import BarsGraph4 from "../../images/milestone2_images/bars4.png"

import "./milestone2.css"

const GraphContainer = ({ image }) => (
    <ModalImage
        imageFile={image}
        imageSize={{width: "520px"}}
        isDarkImage={true}
    />
)

const Milestone2 = () => {
    return <>
        <MilestoneInfoSection title="How we identified the problem">
            <p>
                As the pandemic forced us into our homes, more and more people tried their luck in the kitchen both for survival reasons and as
                a pastime. Most of our team found themselves struggling to enjoy our time, as cooking may feel overwhelming to dopamine drained
                young adults with short attention spans. We faced another problem born from the main disadvantages of the 21st century, that we
                found little meaning in cooking as progress is too hard to assess, and as our calories and kitchen sinks fill up, it is very easy
                to think that we are doing everything wrong. And how can you love something you think you are bad at? 
            </p>

            <p>
                With this problem on our hands we had to verify that we are not the only people that need special care in the kitchen. As we discussed
                with our close friends and our acquaintances, we were happily surprised to find that most young adults from our circles of friends
                suffer from finding little meaning in enhancing their cooking skills and sticking to the two or three recipes that are easy to cook
                and can offer survival. With this insight we journeyed further on our way of building a startup (or at least the DSS project) with the
                goal of finding our key demographic, however small or niche it may be.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="What we propose">
            <p>
                As highly digitalized Zoomers that are way too obsessed with video games and virtual progression, we naturally came up with Cookhub, a
                cooking assistant that will help its users to evolve their cooking skills by creating a sense of progression with the help of gamification
                and cooking challenges. We believe that by creating a challenging but friendly environment we will help people to view cooking as a fun
                activity that will help not only their survival needs, but also offers culinary experiences that they can be proud of, right in their
                kitchens. Also, we believe that by using our app, users will come to believe that cooking is not only a healthy habit, but a skill they
                can value and share with their friends.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The customer discovery process">
            <p>
                We believe that our main user demographic will consist of teenagers and young adults, as they are the early adopters of every new hip
                and trendy app. The two ways we tried to reach them are through a shareable Google Form that we shared with our friends and family,
                and interviews with people that accept to describe their problem highly detailed. Unfortunately, the 2 interviews we managed to plan
                are after the milestone deadline, but we will update the fiki with the new insights.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The results">
            <div className="graphs-wrapper">
                <GraphContainer image={PieGraph1}/>
                <GraphContainer image={PieGraph2}/>
                <GraphContainer image={PieGraph3}/>
                <GraphContainer image={BarsGraph1}/>
                <GraphContainer image={PieGraph4}/>
                <GraphContainer image={BarsGraph2}/>
                <GraphContainer image={BarsGraph3}/>
                <GraphContainer image={PieGraph5}/>
                <GraphContainer image={PieGraph6}/>
                <GraphContainer image={PieGraph7}/>
                <GraphContainer image={PieGraph8}/>
                <GraphContainer image={BarsGraph4}/>
                <GraphContainer image={PieGraph9}/>
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Main insights">
            <p>
                The main insight that had an impact is the high desire of the users to have the option to easily adapt the recipes to their own taste,
                functionality that surpasses the progress based on gamification, which we believed to be our main selling point. Also, the idea of being
                challenged by our assistant really caught on with the people that answered our form, as up to 80% of them declaring they would want to
                receive a challenge at least once a week. Therefore, we will focus more on the possibility to adapt recipes to users’ taste and challenge
                them at least once a week to try something new but interesting to themselves.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="What we learned">
            <p>
                At this point, we believe we are not ready to move to the Customer Validation, as our customers would be made from advertisers for
                restaurants and food shop chains. In order to be able to go to our possible customers and ask for validation we need to have our platform
                built and a significant number of daily users to prove that our platform can provide a good marketplace for their ads. Once we will build
                the platform and have at least a few hundreds daily users, we will move to the next stage. However, we believe that with further customer
                research we can prove that we are worth the attention and we are able to have thousands of daily users in no time. What we lack now is a
                bigger sample of users in order to confirm our hopes with a significant part of the key demographic.
            </p>
        </MilestoneInfoSection>
    </>
}

export default Milestone2