import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import Image1 from "../../images/milestone7_images/1.jpg"
import Image2 from "../../images/milestone7_images/2.jpg"
import Image3 from "../../images/milestone7_images/3.jpg"
import Image4 from "../../images/milestone7_images/4.jpg"
import Image5 from "../../images/milestone7_images/5.jpg"
import Image6 from "../../images/milestone7_images/6.jpg"
import Image7 from "../../images/milestone7_images/7.jpg"
import Image8 from "../../images/milestone7_images/8.jpg"
import Image9 from "../../images/milestone7_images/9.jpg"
import Image10 from "../../images/milestone7_images/10.jpg"
import Image11 from "../../images/milestone7_images/11.jpg"
import Image12 from "../../images/milestone7_images/12.jpg"
import Image13 from "../../images/milestone7_images/13.jpg"
import Image14 from "../../images/milestone7_images/14.jpg"
import Image15 from "../../images/milestone7_images/15.jpg"

import MImage1 from "../../images/milestone7_images/M1.jpg"
import MImage2 from "../../images/milestone7_images/M2.jpg"
import MImage3 from "../../images/milestone7_images/M3.jpg"
import MImage4 from "../../images/milestone7_images/M4.jpg"
import MImage5 from "../../images/milestone7_images/M5.jpg"

const Milestone7 = () => {
    return <>
        <MilestoneInfoSection title="Planning for the MVP">
            <p>
                The first step that we had to take when creating the MVP was to look over all the data that we gathered during
                the previous milestones, determine which data is relevant and which data is not, and then design the MVP around
                these aspects.
            </p>

            <p>
                By looking over the data, it was clear to us that the most important aspect we had to focus on is to create a platform that
                encourages you to try out different recipes, to motivate you to cook, while still being user friendly. Almost 30% of the people
                that have answered the survey we carried out during the 2nd milestone have said that the main reason why they do not cook that
                often is the lack of motivation. To add to that, 15% have also stated that they do not cook because they lack a well-defined starting
                point.
            </p>

            <p>
                Another feature that we considered very important was adaptability to the user’s tastes. We felt that a system that offers recipes based
                on personal preferences is a key feature. As such, we had to make sure to implement a rating system that allows our users to rate recipes
                so that we can offer them recommendations based on these ratings.
            </p>

            <p>
                Finally, the last feature that we considered to be very important was one that we discovered during the implementation of the MVP. As we
                were working on the features mentioned above, we realized that, through CookHub, we can create a community that is passionate about cooking.
                In turn, this means that new users can post questions or ask for feedback from other more experienced users. As such, CookHub could become
                more than just an application used to motivate you to cook.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Designing the MVP">
            <p>
                Since we already created a very limited demo for the 5th milestone, we already had a framework from which we could expand and thus create
                the MVP. Since this demo received very positive feedback, we decided to keep all the features presented so that we can develop them further.
            </p>

            <p>
                The most praised aspect of the demo was the user interface. Those who were kind enough to provide feedback noted that the UI is very clean
                and intuitive. Furthermore, they also complimented the overall look of the platform, appreciating its consistent design and theme.
            </p>

            <p>
                This response was somewhat expected as we spent a considerable amount of time thinking about the user experience during the 4th milestone.
                The key aspect when designing the UI was ease of use. We wanted the users to be able to access almost any page as easy as possible. This is
                the reason why we opted to use a hamburger menu for navigation: it is the most elegant and intuitive way of presenting our users all the possible
                pages that they can access, and it is also present on every page the user accesses.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image1}
                    imageSize={{width: "440px"}}
                    imageTitle="Login screen"
                />
                <ModalImage
                    imageFile={Image2}
                    imageSize={{width: "440px"}}
                    imageTitle="Acount creation screen"
                />
                <ModalImage
                    imageFile={Image3}
                    imageSize={{width: "440px"}}
                    imageTitle="The dashboard"
                />
                <ModalImage
                    imageFile={Image4}
                    imageSize={{width: "440px"}}
                    imageTitle="The hamburger menu"
                />
            </div>
            
            <p>
                We also continued with the theme we have used for the demo, seeing how users enjoyed it. We feel that the white with green accents used in all
                the UI elements provides a clean look that is very readable, while also being somewhat reminiscent of the clothes that chefs wear. We toyed with
                the idea of also implementing a dark mode for our application, but we decided that it is beyond the scope of the MVP.
            </p>

            <p>
                In order to motivate our users to cook, we decided to use gamification, or more specifically, periodic challenges that drive you to try out
                new recipes. As per the feedback of the people we have surveyed during the 2nd milestone, these challenges change every week. However, we
                also decided to implement a monthly challenge which consists of progressively harder recipes that are all based around a specific theme.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image9}
                    imageSize={{width: "440px"}}
                    imageTitle="Monthly challenge screen"
                />
            </div>

            <p>
                To also motivate our users to cook more, we also decided to implement achievements. Therefore, we designed a couple of achievements which
                our users can proudly display to the rest of the world. We felt that this feature not only motivates those that like to showcase their skills,
                but it also provides a fun way for our users to track their progress.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image12}
                    imageSize={{width: "440px"}}
                    imageTitle="Achievements screen"
                />
            </div>

            <p>
                As we have mentioned before, we implemented a way for users to create posts so that they may interact with the other users of the platform.
                We also implemented a way for users to see their own profile as well as the profile of others. Furthermore, we also felt that allowing the
                users to edit their own profile was another important feature, so we implemented that as well.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image5}
                    imageSize={{width: "440px"}}
                    imageTitle="User account screen (Activity tab)"
                />
                <ModalImage
                    imageFile={Image6}
                    imageSize={{width: "440px"}}
                    imageTitle="User account screen (Achievements tab)"
                />
                <ModalImage
                    imageFile={Image7}
                    imageSize={{width: "440px"}}
                    imageTitle="User account screen (Recipes tab)"
                />
                <ModalImage
                    imageFile={Image13}
                    imageSize={{width: "440px"}}
                    imageTitle="User account edit screen"
                />
                <ModalImage
                    imageFile={Image14}
                    imageSize={{width: "440px"}}
                    imageTitle="The profile of another user"
                />
            </div>

            <p>
                As CookHub is a cooking application at its core, we also had to implement a way for our users to explore and find recipes that fit their
                respective cravings. Besides the numerous other ways to access a specific recipe (challenges, another user shares a recipe, user adds a
                recipe, and several others), we also created a page on which our users can see all the recipes. On this page, the users can search for the
                recipe that fits most with what they are looking for.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image15}
                    imageSize={{width: "440px"}}
                    imageTitle="Recipe exploration screen"
                />
            </div>

            <p>
                Once a user accesses a recipe, they are presented with a list of ingredients and steps required to prepare said recipe. Eventually, they can
                also see a gallery containing more images of the recipe that they are preparing to cook.
            </p>

            <p>
                We decided to also implement checkboxes for each element in both the ingredient list and the steps list. This is because we felt that it
                would be a very nice feature for our users when they are preparing the recipe, as they would be able to track the ingredients that they
                currently have (so that they can see what else needs to be bought) or track the steps that they have done when cooking said recipe.
            </p>

            <p>
                Finally, we had to add the rating system which would inform us of their preferences, but it can also be used by the users to tell which
                recipes are worth cooking and which ones are not.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image10}
                    imageSize={{width: "440px"}}
                    imageTitle="Recipe screen (before rating)"
                />
                <ModalImage
                    imageFile={Image11}
                    imageSize={{width: "440px"}}
                    imageTitle="Recipe screen (after rating)"
                />
            </div>

            <p>
                The last feature we decided to add to the MVP was the ability to allow our users to add their own recipes. While this feature has several
                limitations in the MVP, it is still functional enough to showcase what can be expected in the final version of our application.
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={Image8}
                    imageSize={{width: "440px"}}
                    imageTitle="Add recipe screen"
                />
            </div>

            <p>
                And to end it all, the last thing that we had to do was to ensure that the application works on mobile devices as well. Thankfully, due
                to the way in which we planned the layout of the application, modifying the CSS rules to fit mobile devices was a mostly painless task.
                The reason why we felt it was crucial to ensure that the application works on mobile devices is because most people use their phones or
                tablets when following a recipe. Without this feature, the ability to track which steps have been completed and which remain would be a
                mostly pointless feature. 
            </p>

            <div className="landing-image-wrapper">
                <ModalImage
                    imageFile={MImage1}
                    imageSize={{width: "180px"}}
                    imageTitle="Login screen on a mobile device"
                />
                <ModalImage
                    imageFile={MImage2}
                    imageSize={{width: "180px"}}
                    imageTitle="The dashboard on a mobile device"
                />
                <ModalImage
                    imageFile={MImage3}
                    imageSize={{width: "180px"}}
                    imageTitle="The hamburger menu on a mobile device"
                />
                <ModalImage
                    imageFile={MImage4}
                    imageSize={{width: "180px"}}
                    imageTitle="User account screen on a mobile device (Activity tab)"
                />
                <ModalImage
                    imageFile={MImage5}
                    imageSize={{width: "180px"}}
                    imageTitle="Recipe screen on a mobile device"
                />
            </div>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="Conclusions">
            <p>
                Although we weren’t able to implement everything that we wanted to (such as localization or a dark theme), we feel that we
                were able to create a very good MVP that offers a more than complete vision of what the final application may look like.
            </p>
        </MilestoneInfoSection>
    </>
}

export default Milestone7