import React from "react"
import MilestoneInfoSection from "../MilestoneInfoSection/"
import ModalImage from "../ModalImage"

import WebsiteLogin from "../../images/milestone3_images/website/login_screen.png"
import WebsiteDashboardV1 from "../../images/milestone3_images/website/dashboard_screen_v1.png"
import WebsiteDashboardV2 from "../../images/milestone3_images/website/dashboard_screen_v2.png"
import WebsiteNavigationMenu from "../../images/milestone3_images/website/navigation_menu.png"
import WebsiteUserProfile from "../../images/milestone3_images/website/user_profile_screen.png"
import WebsiteExplore from "../../images/milestone3_images/website/explore_screen.png"
import WebsiteRecipe from "../../images/milestone3_images/website/recipe_screen.png"
import WebsiteMonthly from "../../images/milestone3_images/website/monthly_challenges_screen.png"
import WebsiteProgression from "../../images/milestone3_images/website/progression_tree_screen.png"

import MobileLogin from "../../images/milestone3_images/mobile/login_screen.png"
import MobileDashboard from "../../images/milestone3_images/mobile/dashboard_screen.png"
import MobileNavigationMenu from "../../images/milestone3_images/mobile/navigation_menu.png"
import MobileUserProfile from "../../images/milestone3_images/mobile/user_profile_screen.png"
import MobileExplore from "../../images/milestone3_images/mobile/explore_screen.png"
import MobileRecipe from "../../images/milestone3_images/mobile/recipe_screen.png"
import MobileMonthly from "../../images/milestone3_images/mobile/monthly_challenges_screen.png"
import MobileProgression from "../../images/milestone3_images/mobile/progression_tree_screen.png"

import "./milestone3.css"

const WireframeContainer = ({ image, size, title }) => (
    <ModalImage
        imageFile={image}
        imageSize={size}
        imageTitle={title}
    />
)

const Milestone3 = () => {
    const websiteWireframeStyle = {width: "420px"};
    const mobileWireframeStyle = {height: "225px"};

    return <>
        <MilestoneInfoSection title="The goal">
            <p>
                When we started to design the application, we had three main goals in mind: to make a modern and elegant UI, to make the
                application as intuitive and easy to use as possible, and to encourage the user to explore new recipes while constantly
                being motivated by entertaining challenges and other various gamification elements.
            </p>

            <p>
                Since our users will spend most of their time on the dashboard screen, we had to ensure that this screen will contain the
                most relevant information our users would want. Of all the other screens, the dashboard was the only one which went through
                multiple iterations. After several iterations however, we were able to reach a layout that was both visually pleasing, while
                also containing valuable information.
            </p>

            <p>
                In the end, we are very happy with the design of our application and we feel that our users will have a pleasant experience
                while using CookHub.
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The landing page">
            <p>
                The landing page of our application can be found&#160;
                <a className="landing-page-link" href="https://cookhub.coiotii.com/about" target="_blank" rel="noreferrer"> 
                    here.
                </a>
            </p>
        </MilestoneInfoSection>

        <MilestoneInfoSection title="The wireframes">
            <div className="wireframe-section">
                <p className="wireframe-section-title"> Website Version </p>
                <div className="wireframe-wrapper">
                    <WireframeContainer
                        image={WebsiteLogin}
                        size={websiteWireframeStyle}
                        title="Website Login Screen"
                    />
                    <WireframeContainer
                        image={WebsiteDashboardV1}
                        size={websiteWireframeStyle}
                        title="Website Dashboard - First Iteration"
                    />
                    <WireframeContainer
                        image={WebsiteDashboardV2}
                        size={websiteWireframeStyle}
                        title="Website Dashboard - Second Iteration"
                    />
                    <WireframeContainer
                        image={WebsiteNavigationMenu}
                        size={websiteWireframeStyle}
                        title="Website Navigation Menu"
                    />
                    <WireframeContainer
                        image={WebsiteUserProfile}
                        size={websiteWireframeStyle}
                        title="Website User Profile"
                    />
                    <WireframeContainer
                        image={WebsiteExplore}
                        size={websiteWireframeStyle}
                        title="Website Explore Screen"
                    />
                    <WireframeContainer
                        image={WebsiteRecipe}
                        size={websiteWireframeStyle}
                        title="Website Recipe Screen"
                    />
                    <WireframeContainer
                        image={WebsiteMonthly}
                        size={websiteWireframeStyle}
                        title="Website Monthly Challenges"
                    />
                    <WireframeContainer
                        image={WebsiteProgression}
                        size={websiteWireframeStyle}
                        title="Website Progression Tree"
                    />
                </div>
            </div>

            <div className="wireframe-section">
                <p className="wireframe-section-title"> Mobile Version </p>
                <div className="wireframe-wrapper">
                    <WireframeContainer
                        image={MobileLogin}
                        size={mobileWireframeStyle}
                        title="Mobile Login Screen"
                    />
                    <WireframeContainer
                        image={MobileDashboard}
                        size={mobileWireframeStyle}
                        title="Mobile Dashboard"
                    />
                    <WireframeContainer
                        image={MobileNavigationMenu}
                        size={mobileWireframeStyle}
                        title="Mobile Navigation Menu"
                    />
                    <WireframeContainer
                        image={MobileUserProfile}
                        size={mobileWireframeStyle}
                        title="Mobile User Profile"
                    />
                    <WireframeContainer
                        image={MobileExplore}
                        size={mobileWireframeStyle}
                        title="Mobile Explore Screen"
                    />
                    <WireframeContainer
                        image={MobileRecipe}
                        size={mobileWireframeStyle}
                        title="Mobile Recipe Screen"
                    />
                    <WireframeContainer
                        image={MobileMonthly}
                        size={mobileWireframeStyle}
                        title="Mobile Monthly Challenges"
                    />
                    <WireframeContainer
                        image={MobileProgression}
                        size={mobileWireframeStyle}
                        title="Mobile Progression Tree"
                    />
                </div>
            </div>
        </MilestoneInfoSection>
    </>
}

export default Milestone3